import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
const BASE_URL = environment.BASE_URL();

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

constructor(private http: HttpClient) { }
 
 getSettingsProfileData(userId:number){

  return this.http.get<any>(`${BASE_URL}/settingsPage?userid=${userId}`);
 }

 saveBusinessProfile(setting:object){
  return this.http.post<any>(`${BASE_URL}/saveBusinessProfile`,setting);
 }

 getBusinessProfileData(userId:number){
  
  return this.http.get<any>(`${BASE_URL}/settingsPageData?userid=${userId}`);
 }
 getProfileCompletedPercentage(userId:number){
  
  return this.http.get<any>(`${BASE_URL}/settingsPageGraph?userid=${userId}`);
 }
 

}
