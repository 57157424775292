<app-sidebar></app-sidebar>
<ngx-ui-loader></ngx-ui-loader>
<div id="contentSelection" class='content-section'>
<app-navbar></app-navbar>

<router-outlet></router-outlet>
<app-footer></app-footer>
</div>





