<nav class="navbar navbar-expand-lg"> <a class="toggle-switch"[routerLink]=""><span id="navBarSwitchRight" class="switch-right"><i class="las la-bars"></i></span><span id= "navBarSwitchLeft" class="switch-left" style="display: none;"><i class="las la-times"></i></span></a>
  <nav aria-label="breadcrumb" class="breadcrumb-section">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a [routerLink]="['/dashboard']" class="home"><i class="las la-home"></i></a></li>
     <!-- <li class="breadcrumb-item" [routerLink]="['/dashboard']" class="home"></li> -->
      <li *ngFor="let headerPath of currentHeaderPaths"class="breadcrumb-item invalid-link" >
        {{headerPath.title}}
      </li>
    </ol>
  </nav>
  <ul class="navbar-nav nav-right order-lg-3 ml-auto">
    <!-- arni -->
    <li class="nav-item dropdown alerts"> <a class="nav-link notification" [routerLink]="" id="alerts" role="button" data-toggle="modal"   data-target="#exampleModalCenter" (click)="contactusload()"> <i class="far fa-envelope"> </i> </a>
      

<!-- Modal -->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Contact Us</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" >
        <div class="check" *ngIf ="reqsent">
          <i class="fa fa-check" aria-hidden="true"></i>
          <p>{{message}}</p>
         </div>
        <div *ngIf = "commentform">
        <textarea rows="8" cols="53" placeholder="Kindly share your queries or input to help us serve you better..." #txtarea></textarea>
        <button type="button" class="btn btn-success" (click)="contactus(txtarea.value)">Submit</button>
      </div>
      </div>
      <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        
      </div> -->
    </div>
  </div>
</div>
    <!-- arni -->

    <li class="nav-item dropdown alerts"> <a class="nav-link notification" [routerLink]="" id="alerts" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"  (click)="notification()" > <i class="far fa-bell" > <span class="badge badge-danger badge-pill">{{notilen}}</span> </i> </a>
      <div class="dropdown-menu" aria-labelledby="navbarDropdown" >
        <div class="alert-title" >Notifications </div>
        <div class="alert-section"  >
          
          
          <!-- <div class="alert-desc" *ngFor="let notification of notificationarray"  (click)="selectedsource($event)">
            <button class="dropdown-item" [attr.data-empid] = "notification.id" >
            <h6 > {{notification.source}}</h6>
            <p>New data added for the {{notification.source}} </p>
            <div class="time">{{notification.datetime}}</div>
            </button>
          </div> -->
          <div class="alert-desc" *ngFor="let notification of notificationarray" >
            <button class="dropdown-item" (click)="selectedsource(notification.id , notification['sourceid'])">
              
            <h6 [ngStyle]="{'font-weight': notification.readStatus =='yes' ? 'normal' : 'bold' }"> {{notification.source}}</h6>
            <p [ngStyle]="{'font-weight': notification.readStatus =='yes' ? 'normal' : 'bold' }">New data added for the {{notification.source}} </p>
            <div class="time" [ngStyle]="{'font-weight': notification.readStatus =='yes' ? 'normal' : 'bold' }">{{notification.datetime}}</div>
        
            </button>
          </div>
         
       
        </div>
        
      </div>
    </li>
  
    <li class="nav-item dropdown profile"> <a class="nav-link" href="#" id="profile" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i class="lar la-user-circle"></i> </a>
      <div class="dropdown-menu" aria-labelledby="navbarDropdown">
        <div class="user-info">
          <h6>{{userName}}</h6>
          <p>{{userEmail}}</p>
        </div>
        <a class="dropdown-item text-blue"  routerLink="/profile" href="#"><i class="fa fa-user text-blue"></i> Social Media Connect</a> 
        <a class="dropdown-item"  routerLink="/settings"><i class="fas fa-cog text-warning"></i> Settings</a> 
        <!-- <a class="dropdown-item" href="#"><i class="fa fa-key text-success"></i> Change Password</a>  -->
         <a class="dropdown-item" [routerLink]=""  ><i class="lar la-user-circle  text-success"></i>Account</a>
          <a class="dropdown-item" [routerLink]=""  (click)="logout()"><i class="fas fa-power-off text-danger"></i> Logout</a> </div>
          
    </li>
  </ul>
</nav>