import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';
const BASE_URL = environment.BASE_URL();

@Injectable({
  providedIn: 'root'
})
export class ReviewService {

constructor(private http: HttpClient) { }

 getReviewsPageMasterData(userId:number){
 
  return this.http.get<any>(`${BASE_URL}/reviewsPageMasterData?userid=${userId}`);
 }

 searchBusinessReviewsData(seachFilterObj:any){

  let reqParameters = `userid=${seachFilterObj.userid}
  &startno=${seachFilterObj.startno}&noRecords=${seachFilterObj.noRecords}`;
  
  if(seachFilterObj.locationIds && seachFilterObj.locationIds.length){
    reqParameters +=  `&locationIds=${seachFilterObj.locationIds.join("|")}`
  }
  if(seachFilterObj.sourceIds && seachFilterObj.sourceIds.length){
    reqParameters +=  `&sourceIds=${seachFilterObj.sourceIds.join("|")}`
  }
  if(seachFilterObj.ratings && seachFilterObj.ratings.length){
    reqParameters +=  `&ratings=${seachFilterObj.ratings.join("|")}`
  }
  if(seachFilterObj.sentiments && seachFilterObj.sentiments.length){
    reqParameters +=  `&sentiments=${seachFilterObj.sentiments.join("|")}`
  }
  if(seachFilterObj.fromDate){
    reqParameters +=  `&fromDate=${seachFilterObj.fromDate}`
  }
  if(seachFilterObj.toDate){
    reqParameters +=  `&toDate=${seachFilterObj.toDate}`
  }
  if(seachFilterObj.keywords && seachFilterObj.keywords.trim().length){
    reqParameters +=  `&keywords=${seachFilterObj.keywords}`
  }
  
  return this.http.get<any>(`${BASE_URL}/reviewsPageData?${reqParameters}`);
 }

}
