import { Pipe, PipeTransform } from '@angular/core';
const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
      ];

@Pipe({ name: 'customDateFormat' })
export class CustomDateFormat implements PipeTransform {
    transform(date: any): any {
        if (!date) {
            return date;
        }
      
        if (Object.prototype.toString.call(new Date(date)) === "[object Date]") {
            // it is a date
            if (isNaN((new Date(date)).getTime())) {  // d.valueOf() could also work
             
                return date;
            } else {
                let cDate = new Date(date);
                return cDate.getDate() + " " + monthNames[cDate.getMonth()] + ", " + cDate.getFullYear();
            }
          } else {
         
            return date;
          }
    } 
}