import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

const BasicAuthKEY = environment.basicAuthKey;

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor() {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
       
            request = request.clone({
                setHeaders: { 
                    Authorization: `Basic ${BasicAuthKEY}`
                }
            });
        
        return next.handle(request);
    }
}