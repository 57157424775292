import { Injectable } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  constructor(private ngxService: NgxUiLoaderService) { }

  startSpinner() {
    this.ngxService.start()
  }

  stopSpinner() {
    this.ngxService.stop();
  }
  
  startBackgroundSpinner(loderId) {
    this.ngxService.startBackgroundLoader(loderId);
  }

  stopBackgroundSpinner(loderId) {
    this.ngxService.stopBackgroundLoader(loderId);
  }

  startSpecficLoader(loaderName: string) {
    this.ngxService.startLoader(loaderName);
  }

  stopSpecficLoader(loaderName: string) {
    this.ngxService.stopLoader(loaderName);
  }
  
  stopAllLoader(){
    this.ngxService.stopAll()
  }

}
