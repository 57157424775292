import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, AfterContentInit } from '@angular/core';
import { environment } from "../../../../environments/environment";

declare var $: any;
@Component({
  selector: 'app-captcha-generator',
  templateUrl: './captcha-generator.component.html',
  styleUrls: ['./captcha-generator.component.css']
})
export class CaptchaGeneratorComponent implements OnInit {
  @Output() captchaData = new EventEmitter();

  @ViewChild('myCanvas') myCanvas: ElementRef;
  public context: CanvasRenderingContext2D

  captcha: string = "";
  backgroundImage: string = "";
  constructor() {

  }

  ngOnInit() {
    this.generateCaptcha();
  }

  generateCaptcha() {
    var background = [1, 2, 3, 4];
    var alpha = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'j', 'k', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    for (var i = 0; i < 6; i++) {
      var a = alpha[Math.floor(Math.random() * alpha.length)];
      var b = alpha[Math.floor(Math.random() * alpha.length)];
      var c = alpha[Math.floor(Math.random() * alpha.length)];
      var d = alpha[Math.floor(Math.random() * alpha.length)];
      var e = alpha[Math.floor(Math.random() * alpha.length)];
      var f = alpha[Math.floor(Math.random() * alpha.length)];
    }
    this.captcha = a + b + c + d + e + f;

    this.backgroundImage = "assets/img/captcha-background/1.jpg";
    this.captchaData.emit(this.removeSpaces(this.captcha));

    var captcha = this.captcha;
    setTimeout(function () {
      let canvas: HTMLCanvasElement = <HTMLCanvasElement>document.getElementById('canvas');
      if(canvas)  {
        let ctx: CanvasRenderingContext2D = canvas.getContext('2d');  
       
        //  canvas.width =200
         canvas.height = 38
      //   ctx.drawImage($('#backgroundImage').get(0), 0, 0);
         ctx.font = "24pt Prompt";    
         //refill text
         ctx.fillStyle = "#283481";
         ctx.fillText(captcha, 50, 28);
      }
     
    }, 500)
  }



  refreshCaptcha() {
    this.generateCaptcha();
  }


  public get getCaptcha() {
    return this.removeSpaces(this.captcha);
  }

  removeSpaces(string) {
    return string.split(' ').join('');
  }





}
