import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
let multiPleSheetWorkBook: XLSX.WorkBook = { Sheets: {}, SheetNames: [] };
let excelFileName = "ORM_over_all_report";

@Injectable({
  providedIn: 'root'
})

export class SharedService {

  constructor() { }

  public exportAsSingleSheetExcelFile(json: any[], sheetName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);

    const workbook: XLSX.WorkBook = { Sheets: { [sheetName]: worksheet }, SheetNames: [sheetName] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public emptyMultiPleSheetWorkBook(): void {
    multiPleSheetWorkBook = { Sheets: {}, SheetNames: [] };
  }

  public setExportFileName(fileName: string): void {
    let curDate = new Date();
    let dateFormat = "_" + curDate.getDate() + "_" + ((curDate.getMonth()) + 1) + "_" + curDate.getFullYear() + "_" + curDate.getTime();    
    excelFileName = fileName + dateFormat;

  }

  public exportAsMultipleSheetExcelFile(sheetName: string, sheetData: any[], isFinished = false): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(sheetData);
    multiPleSheetWorkBook["Sheets"][sheetName] = worksheet;
    multiPleSheetWorkBook["SheetNames"].push(sheetName);

    if (isFinished) {
      const excelBuffer: any = XLSX.write(multiPleSheetWorkBook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, excelFileName);
    }

  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }


}
