import { Component, OnInit } from '@angular/core';
import { LoginComponent } from 'src/app/pages/login/login.component';
import { AuthenticationService } from 'src/app/services';
import { Router,ActivatedRoute  } from '@angular/router';
import { userInfo } from 'os';
import { CookieService } from 'ng2-cookies';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  image : any
  userid: any
  enableAllPages:boolean = false;
  imageUrl = "./../../../assets/img/";
  cookiee : string
  default : boolean;
  constructor(
    private cookie: CookieService,
    private router: Router,  
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService) { }
  
  ngOnInit() {
  
    this.cookiee = this.cookie.get('buzz');
    this.imageUrl += this.cookiee+'.png';

    this.authenticationService.CurrentUserData.subscribe(user=>{
      
      this.enableAllPages = user && user.enableAllPages == true ? true : false;
console.log(this.cookiee,'cookiee value');

      if(this.cookiee == "default"){
        this.default = true
     }else{
      this.default = false

     }
     
     
    
    })
   
  }

        
}
