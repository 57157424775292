import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
const BASE_URL = environment.BASE_URL();


@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient) { }

    register(user: any) {
        return this.http.post<any>(`${BASE_URL}/signup`, user);
    }

    resetPassword(user: any) {
        return this.http.post<any>(`${BASE_URL}/doResetPassword`, user);
    }

    getUserDetails() {
        return this.http.get<any[]>(`/users`);
    }

    delete(id: number) {
        return this.http.delete(`/users/${id}`);
    }
    emailverification(tokenInfo: any){

        return this.http.post<any>(`${BASE_URL}/emailverification`, tokenInfo);
    }


} 