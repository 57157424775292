<div class="captchaGenerator ">
  <div class="captcha-bg">
  <!--<img id="backgroundImage"  class="background" src="{{backgroundImage}}" crossorigin="anonymous" />-->
  <canvas id="canvas"></canvas>
  </div>
  <div class="captcha-btn">
  <button type="button"  (click)="refreshCaptcha()" id="refresh" class="btn-blue">
    <i class="las la-redo-alt"></i>
  </button>
    </div>
</div>