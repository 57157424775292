import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ToastService {

constructor() { }

private subject = new Subject<any>();

    add(message: any) {
        this.subject.next(message);
    }

    clearToast() {
        this.subject.next();
    }

    getToast(): Observable<any> {
        return this.subject.asObservable();
    }
}
