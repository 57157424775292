import { Injectable } from '@angular/core';
import { throwError as observableThrowError, Observable, Subject, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
const BASE_URL = environment.BASE_URL();
@Injectable({
  providedIn: 'root'
})

export class NavbarService {

private headerPathSubject = new Subject<any>();

constructor(private http: HttpClient) { }

updateHeaderPath(selectedDates) {
  this.headerPathSubject.next(selectedDates);
}

getHeaderPath(): Observable<any> {
  return this.headerPathSubject.asObservable();
}
postfeedback(userid:any,query:any){
  let headers = new HttpHeaders();
        // {
        //     'Access-Control-Allow-Origin': 'http://localhost:4200',
        //     'Access-Control-Allow-Credentials': true });
        let options = { headers: headers };
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Access-Control-Allow-Credentials', 'true');
  return this.http.post<any>(`${BASE_URL}/postFeedback`,{userid,query},options);
}

getNotification(userId:number){
  return this.http.get<any>(`${BASE_URL}/userNotifications?userid=${userId}`);
}
updateNotification(userid:any,notificationid:any){
  let headers = new HttpHeaders();
  let options = { headers: headers };
  headers.append('Access-Control-Allow-Origin', '*');
  headers.append('Access-Control-Allow-Credentials', 'true');
return this.http.post<any>(`${BASE_URL}/updateNotificationStatus`,{userid,notificationid},options);
}

}
