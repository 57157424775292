
<div id="container" class="card h-100 shadow-none" [attr.id]="aspect['aspectid']+'_card'">

  <div class="card-header">
  <h5>{{ aspect['aspect']}}</h5>
  <ul>
  <li><a href="#" data-toggle="modal" (click)="changeOption(popupChartId,'rating',true)" [attr.data-target]="'#'+ aspect['aspectid']+'_model'"><i class="las la-expand-arrows-alt"></i></a></li>
  <li *ngIf="isOverAllChart==true">
  
  <div class="dropdown download-link"> <a class="download-icon" href="#" data-toggle="dropdown" title="Download"><i class="las la-download"></i></a>
  <div class="dropdown-menu dropdown-menu-right">
  <a class="dropdown-item pointer text-bold" (click)="exportAsXLSX()">Excel</a> </div>
  </div>
  </li>
  </ul>
  </div>
  <div class="card-body charModelHight">
  <ul class="nav nav-pills mb-3 justify-content-end pills-review" [attr.id]=" aspect['aspectid']+'-pills-tab'" role="tablist">
  
  <li class="nav-item"> <a class="nav-link active" (click)="changeOption(mainChartId,'rating')" [attr.id]=" aspect['aspectid']+'-pills-rating-tab'" data-toggle="pill" [attr.href]="'#'+ aspect['aspectid']+'-pills-rating'" role="tab" [attr.aria-controls]=" aspect['aspectid']+'-pills-rating'" aria-selected="true">Rating</a> </li>
  <li class="nav-item"> <a class="nav-link " (click)="changeOption(mainChartId,'reviews')" [attr.id]=" aspect['aspectid']+'-pills-rating-tab'" data-toggle="pill" [attr.href]="'#'+ aspect['aspectid']+'-pills-rating'" role="tab" [attr.aria-controls]=" aspect['aspectid']+'-pills-rating'" aria-selected="false">Reviews</a> </li>
  <li class="nav-item"> <a class="nav-link" (click)="changeOption(mainChartId,'sentiment')" [attr.id]=" aspect['aspectid']+'-pills-rating-tab'" data-toggle="pill" [attr.href]="'#'+ aspect['aspectid']+'-pills-rating'" role="tab" [attr.aria-controls]=" aspect['aspectid']+'-pills-rating'" aria-selected="false">Sentiment</a> </li>
  </ul>
  <div class="tab-content" [attr.id]=" aspect['aspectid']+'-pills-tabContent'" >
  <div class="tab-pane fade show active text-center" [attr.id]=" aspect['aspectid']+'-pills-rating'" role="tabpanel" [attr.aria-labelledby]=" aspect['aspectid']+'-pills-rating-tab'">
  <div
  [attr.id]="mainChartId"
  [ngStyle]="{'padding-left':'1%','width':'98%','height': isOverAllChart?'240px':'200px','display': 'block'}"
  ></div>
  </div>
  </div>
  </div>
  </div>
  
  <div class="modal fade" [attr.id]=" aspect['aspectid']+'_model'" tabindex="-1" role="dialog" aria-hidden="true">
    <!-- <h1>surya</h1> -->
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
  <div class="modal-content">
  <div class="modal-header">
  <h5 class="modal-title" id="exampleModalCenterTitle">{{ aspect['aspect']}} </h5>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
  </div>
  <div class="modal-body">
  
  <ul class="nav nav-pills mb-3 justify-content-end pills-review" id="pills-tab" role="tablist">
  <div class="form-group col-lg-2" style="position: absolute;left: 0px;width:35%">
  
  <p-dropdown class ="chart-type" [options]="chartTypes"
  [(ngModel)]="curPopUpChartType"
  placeholder="Select Chat Type"
  optionLabel="name" [showClear]="false"
  (onChange) = "changeOption(popupChartId,curPopUpTab,true)"
  ngDefaultControl></p-dropdown>
  
  </div>
  
  <li class="nav-item"> <a class="nav-link active" (click)="curPopUpTab='rating';changeOption(popupChartId,'rating',true);" id="pills-rating-tab1" data-toggle="pill" href="#pills-rating1" role="tab" aria-controls="pills-rating1" aria-selected="true">Rating</a> </li>
  <li class="nav-item"> <a class="nav-link" (click)="curPopUpTab='reviews';changeOption(popupChartId,'reviews',true);" id="pills-rating-tab1" data-toggle="pill" href="#pills-rating1" role="tab" aria-controls="pills-rating1" aria-selected="false">Reviews</a> </li>
  <li class="nav-item"> <a class="nav-link" (click)="curPopUpTab='sentiment';changeOption(popupChartId,'sentiment',true);" id="pills-rating-tab1" data-toggle="pill" href="#pills-rating1" role="tab" aria-controls="pills-rating1" aria-selected="false">Sentiment</a> </li>
  </ul>
  <div class="tab-content" id="pills-tabContent">
  <div class="tab-pane fade show active text-center" id="pills-rating1" role="tabpanel" aria-labelledby="pills-rating-tab1">
  <div
  [attr.id]="popupChartId"
  style=" padding-left:1%;width: 98%; height: 400px; display: block;"></div>
  </div>
  </div>
  </div>
  </div>
  </div>
  </div>