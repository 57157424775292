import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
const BASE_URL = environment.BASE_URL();

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

constructor(private http: HttpClient) { }

getSoicalMediaConnectStatus(userId: number) {
    return this.http.get<any>(`${BASE_URL}/socialMediaCheck?userid=${userId}`);
}

getTwitterAuthLink(userId: any) {
    return this.http.get<any>(`${BASE_URL}/generateTwitterAuthLink?userid=${userId}`);
}

disConnectSocialLink(userId: number, type:string) {
  return this.http.get<any>(`${BASE_URL}/deleteSocialLink?userid=${userId}&type=${type}`);
}

sendCapturedTwitterInfo(userInfo:any){
  return this.http.post<any>(`${BASE_URL}/twitterCapture`, userInfo);
}

postMessageAtSocialMedia(postData:any){
  return this.http.post<any>(`${BASE_URL}/socialMediaPost`, postData);
}

postReplyToSocialComment(replyData:any){
  return this.http.post<any>(`${BASE_URL}/socialMediaReply`, replyData);
}

}
