import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { delay, mergeMap, materialize, dematerialize } from 'rxjs/operators';
// import * as data from './assets/sample.json';

// array in local storage for registered users
let users = JSON.parse(localStorage.getItem('users')) || [];

@Injectable()
export class FakeBackendInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const { url, method, headers, body } = request;

        // wrap in delayed observable to simulate server api call
        return of(null)
            .pipe(mergeMap(handleRoute))
            .pipe(materialize()) // call materialize and dematerialize to ensure delay even if an error is thrown (https://github.com/Reactive-Extensions/RxJS/issues/648)
            .pipe(delay(500))
            .pipe(dematerialize());

        function handleRoute() {
            switch (true) {
                case url.endsWith('/apimaster/api/v1/login') && method === 'POST':
                    return authenticate();
                case url.endsWith('/apimaster/api/v/signup') && method === 'POST':
                    return register();
                case url.endsWith('/apimaster/api/v1/users') && method === 'GET':
                    return getUsers();
                    case url.endsWith('/apimaster/api/v1/settingsPage') && method === 'GET':
                    return getSettingData();
                case url.match(/\/users\/\d+$/) && method === 'DELETE':
                    return deleteUser();
                default:
                    // pass through any requests not handled above
                    return next.handle(request);
            }    
        }

        function getSettingData(){
            return ok('empty data from fake server');
        }

        // route functions

        function authenticate() {
            //console.log("authenticate");
             const { username, password } = body;
          //  console.log("body name:",username," password:",password);
            const user = users.find(x => x.username === username && x.password === password);
            if (!user) {
          //      console.log("invalid user");
                return error('username or password is incorrect');
            }
           // console.log("valid user!!!!!!");
            return ok({
                success:true,
                data:[{
                    id: user.id,
                    username: user.username,
                    email: user.email,
                    roleid:1
                }]
            })
        }

        function register() {
           // console.log("reginstering new user...");
            const user = body

            if (users.find(x => x.username === user.username)) {
               // console.log('username "' + user.username + '" is already taken');
                return error('username "' + user.username + '" is already taken')
            }

            user.id = users.length ? Math.max(...users.map(x => x.id)) + 1 : 1;
            users.push(user);
           // console.log('user '+user);
            localStorage.setItem('users', JSON.stringify(users));
            // console.log('user '+user);
            return ok({success:true,message:'registration completed..'});
        }

        function getUsers() {
            // if (!isLoggedIn()) return unauthorized();
            return ok(users);
        }

        function deleteUser() {
            if (!isLoggedIn()) return unauthorized();

            users = users.filter(x => x.id !== idFromUrl());
            localStorage.setItem('users', JSON.stringify(users));
            return ok();
        }

        // helper functions

        function ok(body?) {
            return of(new HttpResponse({ status: 200, body }))
        }

        function error(message) {
            return throwError({ error: { message } });
        }

        function unauthorized() {
            return throwError({ status: 401, error: { message: 'Unauthorised' } });
        }

        function isLoggedIn() {
            return headers.get('Authorization') === 'Bearer fake-jwt-token';
        }

        function idFromUrl() {
            const urlParts = url.split('/');
            return parseInt(urlParts[urlParts.length - 1]);
        }
    }
}

export const fakeBackendProvider = {
    // use fake backend in place of Http service for backend-less development
    provide: HTTP_INTERCEPTORS,
    useClass: FakeBackendInterceptor,
    multi: true
};