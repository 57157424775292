import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
const BASE_URL = environment.BASE_URL();

@Injectable({
  providedIn: 'root'
})
export class BusinessKPIService {

constructor(private http: HttpClient) { }

getPageMasterData(userId:number){
 return this.http.get<any>(`${BASE_URL}/kpiPageMasterData?userid=${userId}`);
 }

getAspectWiseData(trendReqObj:any){
  let reqParameters = `userid=${trendReqObj.userid}`;
//location
if(trendReqObj.locationIds && trendReqObj.locationIds.length){
  console.log(trendReqObj.locationIds.length,"totallocationlen");
  if(trendReqObj.totalLocationLen == trendReqObj.locationIds.length){
    reqParameters +=  `&locationIds=`
  }else{
    reqParameters +=  `&locationIds=${trendReqObj.locationIds.join("|")}`

  }
  
}
if(trendReqObj.fromDate){
  reqParameters +=  `&fromDate=${trendReqObj.fromDate}`
}
if(trendReqObj.toDate){
  reqParameters +=  `&toDate=${trendReqObj.toDate}`
}


//source
if(trendReqObj.sourceIds && trendReqObj.sourceIds.length){
  if(trendReqObj.totalSourceLen == trendReqObj.sourceIds.length){
    reqParameters +=  `&sourceIds=`
  }else{
    reqParameters +=  `&sourceIds=${trendReqObj.sourceIds.join("|")}`

  }
}

  if(trendReqObj.aspectName){
    reqParameters += `&aspectName=${trendReqObj.aspectName}`

  }
  

 return this.http.get<any>(`${BASE_URL}/kpiPageAspectWiseData?${reqParameters}`);
}

getBusinessTrendsData(trendReqObj:any){
  
  let reqParameters = `userid=${trendReqObj.userid}`;
//location
if(trendReqObj.locationIds && trendReqObj.locationIds.length){
  console.log(trendReqObj.locationIds.length,"totallocationlen");
  if(trendReqObj.totalLocationLen == trendReqObj.locationIds.length){
    reqParameters +=  `&locationIds=`
  }else{
    reqParameters +=  `&locationIds=${trendReqObj.locationIds.join("|")}`

  }
  
}
if(trendReqObj.fromDate){
  reqParameters +=  `&fromDate=${trendReqObj.fromDate}`
}
if(trendReqObj.toDate){
  reqParameters +=  `&toDate=${trendReqObj.toDate}`
}


//source
if(trendReqObj.sourceIds && trendReqObj.sourceIds.length){
  if(trendReqObj.totalSourceLen == trendReqObj.sourceIds.length){
    reqParameters +=  `&sourceIds=`
  }else{
    reqParameters +=  `&sourceIds=${trendReqObj.sourceIds.join("|")}`

  }
  
}
  return this.http.get<any>(`${BASE_URL}/kpiPageTopData?${reqParameters}`);
 }

}
