import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { SharedService } from './components/shared.service';
import { AlertComponent } from './components/alert/alert.component';
import { CaptchaGeneratorComponent } from './components/captcha-generator/captcha-generator.component';
import { ChartComponent } from './components/chart/chart.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { FormsModule } from '@angular/forms';
import { CustomDateFormat } from './pipes/cutom-date-format.pipe';
import {DropdownModule} from 'primeng/dropdown';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,      
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    RouterModule,
    HighchartsChartModule,
    DropdownModule
  ],
  declarations: [
    AlertComponent,
    CaptchaGeneratorComponent,
    ChartComponent,
    CustomDateFormat
  ],
  exports:[
    AlertComponent,
    CaptchaGeneratorComponent,
    ChartComponent,
    CustomDateFormat
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
],
  providers: [SharedService]
})
export class SharedModule { }
