import { Component, OnInit, HostListener } from "@angular/core";

declare var $: any;
@Component({
  selector: "app-default-layout",
  templateUrl: "./default-layout.component.html",
  styleUrls: ["./default-layout.component.css"]
})

export class DefaultLayoutComponent implements OnInit {
  navBarSwitchRight:any;
  navBarSwitchLeft:any;
  contentSselection:any;
  sideBar:any;
 
  @HostListener('window:resize', ['$event']) onResize(event) {
    this.resizeFunc(event.target.innerWidth);
 }
  constructor( ) {}

  ngOnInit() {
    $(() => {
    $('[data-toggle="tooltip"]').tooltip();
    });
    
    this.navBarSwitchRight = <HTMLElement>document.getElementById('navBarSwitchRight');
    this.navBarSwitchLeft = <HTMLElement>document.getElementById('navBarSwitchLeft');
    this.contentSselection = <HTMLElement>document.getElementById('contentSelection');
    this.sideBar = <HTMLElement>document.getElementById('sideBar');
    
    this.navBarSwitchRight.addEventListener("click",(e:Event) =>{
      this.sideBar.classList.add('close-left');
      this.contentSselection.classList.add('close-right');
      this.navBarSwitchRight.style.display = 'none';
      this.navBarSwitchLeft.style.display = 'inline';
    });

    this.navBarSwitchLeft.addEventListener("click",(e:Event) =>{
        this.sideBar.classList.remove('close-left');
        this.contentSselection.classList.remove('close-right');
        this.navBarSwitchRight.style.display = 'inline';
        this.navBarSwitchLeft.style.display = 'none';
      });
      
  }
  public resizeFunc(size){
    if(size < 768){			
      this.sideBar.classList.add('close-left');
      this.contentSselection.classList.add('close-right');
      this.navBarSwitchRight.style.display = 'none';
      this.navBarSwitchLeft.style.display = 'inline';
		}else{
			this.sideBar.classList.remove('close-left');
        this.contentSselection.classList.remove('close-right');
        this.navBarSwitchRight.style.display = 'inline';
        this.navBarSwitchLeft.style.display = 'none';
		} 
  }
 
}
