import { Component, OnDestroy,  HostListener } from '@angular/core';
import { AuthenticationService, ToastService } from './services';
import {MessageService} from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [MessageService]
})

export class AppComponent implements OnDestroy {
  title = 'ORM';
  subscription: Subscription;

  @HostListener('document:click', ['$event']) // update page expiry on every click..
  clickout() {
    if(this.authenticationService.getWithExpiry('xtractiotUser')){
      this.authenticationService.updateLoginSessionExpiry();  
    } 
  }
  constructor(
    private authenticationService: AuthenticationService,
    private messageService: MessageService,
    private toastService:ToastService) {
      this.subscription = this.toastService.getToast().subscribe(message => {
        if (message) {
          this.messageService.add(message);
        }
      });
    }

ngOnDestroy() {
  this.subscription.unsubscribe();
}

}
