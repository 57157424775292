import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services';
import { Router } from '@angular/router';

@Injectable()
export class ExpiryInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService,  private router: Router) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const currentUser = this.authenticationService.currentUserValue;
       if(!currentUser){
        return next.handle(request);
       }else{
           if(this.authenticationService.getWithExpiry('xtractiotUser')){
            this.authenticationService.updateLoginSessionExpiry();   
            return next.handle(request);
           }else{
            this.authenticationService.logout();
            this.router.navigate(['/login']);
           }
          
       }
         
    }
}